import * as Sentry from "@sentry/sveltekit";

// If you don't want to use Session Replay, remove the `Replay` integration,
// `replaysSessionSampleRate` and `replaysOnErrorSampleRate` options.
Sentry.init({
  dsn: "https://3ff26ef12efb386fd31422358e3535be@o4506485273198592.ingest.sentry.io/4506823480442880",
  // tracesSampleRate: 1,
  // replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
  integrations: [Sentry.replayIntegration()],
});

export const handleError = Sentry.handleErrorWithSentry();
